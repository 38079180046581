import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { postRequest, getRequest, patchRequest, deleteRequest } from 'api/httpRequests'

const initialState = {
  loading: false,
  error: '',
  isSuccess: false,
  agencyPlans: null,
  msgText: '',
}

export const addPlan = createAsyncThunk('addPlan', async data => {
  const res = await postRequest(`agency-plan`, data)
  return res
})

export const getPlan = createAsyncThunk('getPlan', async data => {
  const endPointUrl = data.isPublic
    ? `agency-plan/${data.agencyId}?isPublic=true`
    : `agency-plan/${data.agencyId}`
  const res = await getRequest(endPointUrl)
  return res
})

export const updatePlan = createAsyncThunk('updatePlan', async data => {
  const endPointUrl = `agency-plan/${data.agencyId}/${data.id}`
  delete data.id
  delete data.agencyId
  const res = await patchRequest(endPointUrl, data)
  return res
})
export const deletePlan = createAsyncThunk('deletePlan', async data => {
  const endPointUrl = `agency-plan/${data.agencyId}/${data.planId}`
  delete data.planId
  delete data.agencyId
  console.log('here calling', endPointUrl)
  const res = await deleteRequest(endPointUrl)
  return res
})

const agencyPlanReducer = createSlice({
  name: 'agencyPlan',
  initialState,
  reducers: {
    resetValues: state => {
      state.error = ''
      state.isSuccess = false
      state.msgText = ''
    },
  },
  extraReducers: {
    [addPlan.fulfilled]: (state, { payload: { error, data } }) => {
      state.loading = false
      if (error) {
        state.error = error
      } else {
        state.isSuccess = true
        state.error = null
        state.agencyPlans = state.agencyPlans ? [...state.agencyPlans, { ...data }] : [data]
        state.msgText = 'Plan has been added successfully'
      }
    },
    [addPlan.pending]: state => {
      state.loading = true
    },
    [updatePlan.fulfilled]: (state, { payload: { error, data } }) => {
      state.loading = false
      if (error) {
        state.error = error
      } else {
        state.isSuccess = true
        state.error = null
        const planIndex = state.agencyPlans.findIndex(plan => plan.id === data.id)
        state.agencyPlans[`${planIndex}`] = data
        state.msgText = 'Plan has been updated successfully'
      }
    },
    [updatePlan.pending]: state => {
      state.loading = true
    },
    [getPlan.fulfilled]: (state, { payload: { error, data } }) => {
      state.loading = false
      if (error) {
        state.error = error
      } else {
        state.error = null
        state.agencyPlans = data || []
      }
    },
    [getPlan.pending]: state => {
      state.loading = true
    },
    [deletePlan.fulfilled]: (state, { payload: { error } }) => {
      state.loading = false
      if (error) {
        state.error = error
      } else {
        state.error = null
        state.isSuccess = true
        state.msgText = 'Plan has been deleted successfully'
      }
    },
    [deletePlan.pending]: state => {
      state.loading = true
    },
  },
})
export const { resetValues } = agencyPlanReducer.actions
export default agencyPlanReducer.reducer
