import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { postRequest, getRequest } from 'api/httpRequests'

const initialState = {
  loading: false,
  error: '',
  sessionId: null,
  paymentStatus: "PAID",
  isSuccess: false,
}

export const initiatePayment = createAsyncThunk('initiatePayment', async data => {
  const res = await postRequest(`payment`, data)
  return res
})

export const getPaymentStatus = createAsyncThunk('getPaymentStatus', async data => {
  const res = await getRequest(`payment/status/${data.orderId}`)
  return res
})

const paymentReducer = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    resetValues: state => {
      state.error = ''
      state.isSuccess = false
      state.paymentLink = null
      state.paymentStatus = null
    },
  },
  extraReducers: {
    [initiatePayment.fulfilled]: (state, { payload: { error, result } }) => {
      state.loading = false
      if (error) {
        state.error = error
      } else {
        state.isSuccess = true
        state.error = null
        state.paymentStatus = null
        state.sessionId = result.sessionId
      }
    },
    [initiatePayment.pending]: state => {
      state.loading = true
    },
    [getPaymentStatus.fulfilled]: (state, { payload: { error, result } }) => {
      state.loading = false
      if (error) {
        state.error = error
      } else {
        state.isSuccess = true
        state.error = null
        state.paymentLink = null
        state.paymentStatus = result
      }
    },
    [getPaymentStatus.pending]: state => {
      state.loading = true
    },
  },
})
export const { resetValues } = paymentReducer.actions
export default paymentReducer.reducer
