import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { postRequest, getRequest } from 'api/httpRequests'

const initialState = {
  loading: false,
  error: '',
  isSuccess: false,
  activityData: null,
}

export const createActivity = createAsyncThunk('createActivity', async data => {
  const res = await postRequest(`activity`, data)
  return res
})
export const fetchActivity = createAsyncThunk('fetchActivity', async data => {
  const res = await getRequest(`activity/${data.agencyId}?${data.queryString}`)
  return res
})

const activityReducer = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    resetValues: state => {
      state.error = ''
      state.isSuccess = false
    },
  },
  extraReducers: {
    [createActivity.fulfilled]: (state, { payload: { error } }) => {
      state.loading = false
      if (error) {
        state.error = error
      } else {
        state.isSuccess = true
        state.error = null
      }
    },
    [createActivity.pending]: state => {
      state.loading = true
    },
    [fetchActivity.fulfilled]: (state, { payload: { error, result } }) => {
      state.loading = false
      if (error) {
        state.error = error
      } else {
        state.isSuccess = true
        state.error = null
        state.activityData = result
      }
    },
    [fetchActivity.pending]: state => {
      state.loading = true
    },
  },
})
export const { resetValues } = activityReducer.actions
export default activityReducer.reducer
