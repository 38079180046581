/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { lazy, Suspense } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from 'react-router-dom'

import Loading from 'components/Loading'

const Login = lazy(() => import('pages/auth/login'))
const Auth = lazy(() => import('pages/auth/auth'))
const Register = lazy(() => import('pages/auth/register'))
const TeamInvite = lazy(() => import('pages/auth/team-invite'))
const InviteSuccess = lazy(() => import('pages/auth/invite-success'))
const Questions = lazy(() => import('pages/auth/questions'))
const Plans = lazy(() => import('pages/auth/plans'))

const Activity = lazy(() => import('pages/activity'))
const ActivityHistory = lazy(() => import('pages/activity-history'))
const Tasks = lazy(() => import('pages/tasks'))
const MyProfile = lazy(() => import('pages/my-profile'))
// const Notifications = lazy(() => import('pages/notifications'))
const Contacts = lazy(() => import('pages/contacts'))
const ContactsDetails = lazy(() => import('pages/contacts/contacts-details'))
const ContactsAdd = lazy(() => import('pages/contacts/Add'))
const ContactsCompare = lazy(() => import('pages/contacts/compare'))
const PublicContactAdd = lazy(() => import('pages/public/contacts/Add'))
const WpPublicContactAdd = lazy(() => import('pages/public/contacts/Add'))
const ContactBioData = lazy(() => import('pages/public/bio-data'))
const ContactBioDataText = lazy(() => import('pages/public/bio-data-text'))
const ContactUploadSuccess = lazy(() => import('pages/public/upload-success'))
const PaymentStatus = lazy(() => import('pages/public/payment-status'))
const AgencyPlan = lazy(() => import('pages/agencyPlans'))
const AgencyPlansForm = lazy(() => import('pages/agencyPlans/PlansForm'))
const AgencyPlansList = lazy(() => import('pages/agencyPlans/PlanList'))
const Dashboard = lazy(() => import('pages/dashboard'))
const SubscriptionDetails = lazy(() => import('pages/subscription/details'))
const CommingSoon = lazy(() => import('pages/comming-soon'))
const IwProfiles = lazy(() => import('pages/iw-profiles'))
const IwMainPage = lazy(() => import('pages/iw-profiles/main'))
const ExploreProfiles = lazy(() => import('pages/iw-profiles/explore'))
const Agency = lazy(() => import('pages/Agency'))
const MedM = lazy(() => import('pages/MedM'))
const MediationHelp = lazy(() => import('pages/MediationHelp'))

const PrivateOutlet = ({ isSubscriptionExpired }) => {
  const auth = localStorage.getItem('user') || false
  const userData = auth && auth !== 'undefined' ? JSON.parse(auth) : null

  return userData ? (
    userData.subscriptionId ? (
      isSubscriptionExpired ? (
        <Navigate to="/subscription" />
      ) : (
        <Outlet />
      )
    ) : (
      <Navigate to="/auth/choose-plans" />
    )
  ) : (
    <Navigate to="/auth/login" />
  )
}

const AppRouter = ({ isSubscriptionExpired }) => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<PrivateOutlet isSubscriptionExpired={isSubscriptionExpired} />}>
          <Route
            path=""
            element={
              <Suspense fallback={<Loading />}>
                <Activity />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="contacts"
          element={<PrivateOutlet isSubscriptionExpired={isSubscriptionExpired} />}
        >
          <Route
            path=""
            element={
              <Suspense fallback={<Loading />}>
                <Contacts />
              </Suspense>
            }
          />
          <Route
            path="request-help"
            element={
              <Suspense fallback={<Loading />}>
                <Contacts />
              </Suspense>
            }
          />
          <Route
            path=":contactId"
            element={
              <Suspense fallback={<Loading />}>
                <ContactsDetails />
              </Suspense>
            }
          />
          <Route
            path="matches/:contactId"
            element={
              <Suspense fallback={<Loading />}>
                <Contacts />
              </Suspense>
            }
          />
          <Route
            path="add"
            element={
              <Suspense fallback={<Loading />}>
                <ContactsAdd />
              </Suspense>
            }
          />
          <Route
            path="edit"
            element={
              <Suspense fallback={<Loading />}>
                <ContactsAdd />
              </Suspense>
            }
          />
          <Route
            path=":contactId/compare/:compareWithContactId"
            element={
              <Suspense fallback={<Loading />}>
                <ContactsCompare />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="/activity-history/:type"
          element={<PrivateOutlet isSubscriptionExpired={isSubscriptionExpired} />}
        >
          <Route
            path=""
            element={
              <Suspense fallback={<Loading />}>
                <ActivityHistory />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="/iw-profiles"
          element={
            <Suspense fallback={<Loading />}>
              <IwProfiles />
            </Suspense>
          }
        >
          <Route
            index
            element={
              <Suspense fallback={<Loading />}>
                <IwMainPage />
              </Suspense>
            }
          />
          <Route
            path="main"
            element={<PrivateOutlet isSubscriptionExpired={isSubscriptionExpired} />}
          >
            <Route
              path=""
              element={
                <Suspense fallback={<Loading />}>
                  <IwMainPage />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="explore/:userId"
            element={<PrivateOutlet isSubscriptionExpired={isSubscriptionExpired} />}
          >
            <Route
              path=""
              element={
                <Suspense fallback={<Loading />}>
                  <ExploreProfiles />
                </Suspense>
              }
            />
          </Route>
        </Route>
        <Route
          path="/dashboard"
          element={<PrivateOutlet isSubscriptionExpired={isSubscriptionExpired} />}
        >
          <Route
            path=""
            element={
              <Suspense fallback={<Loading />}>
                <Dashboard />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="/inbox"
          element={<PrivateOutlet isSubscriptionExpired={isSubscriptionExpired} />}
        >
          <Route
            path=""
            element={
              <Suspense fallback={<Loading />}>
                <Contacts />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="/favourites"
          element={<PrivateOutlet isSubscriptionExpired={isSubscriptionExpired} />}
        >
          <Route
            path=""
            element={
              <Suspense fallback={<Loading />}>
                <CommingSoon />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="/notifications"
          element={<PrivateOutlet isSubscriptionExpired={isSubscriptionExpired} />}
        >
          <Route
            path=""
            element={
              <Suspense fallback={<Loading />}>
                <CommingSoon />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="/tasks"
          element={<PrivateOutlet isSubscriptionExpired={isSubscriptionExpired} />}
        >
          <Route
            path=""
            element={
              <Suspense fallback={<Loading />}>
                <Tasks />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="/my-profile"
          element={<PrivateOutlet isSubscriptionExpired={isSubscriptionExpired} />}
        >
          <Route
            path=""
            element={
              <Suspense fallback={<Loading />}>
                <MyProfile />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="auth"
          element={
            <Suspense fallback={<Loading />}>
              <Auth />
            </Suspense>
          }
        >
          <Route
            path="questions"
            element={<PrivateOutlet isSubscriptionExpired={isSubscriptionExpired} />}
          >
            <Route
              path=""
              element={
                <Suspense fallback={<Loading />}>
                  <Questions />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="invite-team"
            element={<PrivateOutlet isSubscriptionExpired={isSubscriptionExpired} />}
          >
            <Route
              path=""
              element={
                <Suspense fallback={<Loading />}>
                  <TeamInvite />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="invite-success"
            element={<PrivateOutlet isSubscriptionExpired={isSubscriptionExpired} />}
          >
            <Route
              path=""
              element={
                <Suspense fallback={<Loading />}>
                  <InviteSuccess />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="register"
            element={
              <Suspense fallback={<Loading />}>
                <Register />
              </Suspense>
            }
          />
          <Route
            path="login"
            element={
              <Suspense fallback={<Loading />}>
                <Login />
              </Suspense>
            }
          />
          <Route
            path="choose-plans"
            element={
              <Suspense fallback={<Loading />}>
                <Plans />
              </Suspense>
            }
          />
          <Route
            index
            element={
              <Suspense fallback={<Loading />}>
                <Login />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="/subscription"
          element={
            <Suspense fallback={<Loading />}>
              <SubscriptionDetails />
            </Suspense>
          }
        >
          <Route
            path="details"
            element={<PrivateOutlet isSubscriptionExpired={isSubscriptionExpired} />}
          >
            <Route
              path=""
              element={
                <Suspense fallback={<Loading />}>
                  <SubscriptionDetails />
                </Suspense>
              }
            />
          </Route>
        </Route>
        <Route
          path="agency-plan"
          element={
            <Suspense fallback={<Loading />}>
              <AgencyPlan />
            </Suspense>
          }
        >
          <Route
            index
            element={
              <Suspense fallback={<Loading />}>
                <AgencyPlansList />
              </Suspense>
            }
          />
          <Route
            path="edit/:planId"
            element={<PrivateOutlet isSubscriptionExpired={isSubscriptionExpired} />}
          >
            <Route
              path=""
              element={
                <Suspense fallback={<Loading />}>
                  <AgencyPlansForm />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="create"
            element={<PrivateOutlet isSubscriptionExpired={isSubscriptionExpired} />}
          >
            <Route
              path=""
              element={
                <Suspense fallback={<Loading />}>
                  <AgencyPlansForm />
                </Suspense>
              }
            />
          </Route>
        </Route>
        <Route
          path="upload-profile"
          element={
            <Suspense fallback={<Loading />}>
              <PublicContactAdd />
            </Suspense>
          }
        />
        <Route
          path="wp-upload-profile/:agencyId"
          element={
            <Suspense fallback={<Loading />}>
              <WpPublicContactAdd />
            </Suspense>
          }
        />
        <Route
          path="iwaada-profile"
          element={
            <Suspense fallback={<Loading />}>
              <PublicContactAdd />
            </Suspense>
          }
        />
        <Route
          path="bio-data/:agencyId/:contactId"
          element={
            <Suspense fallback={<Loading />}>
              <ContactBioData />
            </Suspense>
          }
        />
        <Route
          path="bio-data-text/:agencyId/:contactId"
          element={
            <Suspense fallback={<Loading />}>
              <ContactBioDataText />
            </Suspense>
          }
        />
        <Route
          path="upload-success/:contactId"
          element={
            <Suspense fallback={<Loading />}>
              <ContactUploadSuccess />
            </Suspense>
          }
        />
        <Route
          path="payment-status"
          element={
            <Suspense fallback={<Loading />}>
              <PaymentStatus />
            </Suspense>
          }
        />
        <Route
          path="subscription-payment-status"
          element={
            <Suspense fallback={<Loading />}>
              <PaymentStatus />
            </Suspense>
          }
        />
        <Route
          path="/agency"
          element={
            <Suspense fallback={<Loading />}>
              <Agency />
            </Suspense>
          }
        />
        <Route
          path="/mediation"
          element={
            <Suspense fallback={<Loading />}>
              <MedM />
            </Suspense>
          }
        />
        <Route
          path="/mediation_help"
          element={
            <Suspense fallback={<Loading />}>
              <MediationHelp />
            </Suspense>
          }
        />
      </Routes>
    </Router>
  )
}

export default AppRouter
