import React from 'react'
import './loading.css'

const index = () => {
  return (
    <div className="fancy-spinner">
      <div className="ring" />
      <div className="ring" />
      <div className="dot" />
    </div>
  )
}

export default index
