import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { postRequest, getRequest } from 'api/httpRequests'

const initialState = {
  loading: false,
  error: '',
  isSuccess: false,
  details: null,
  sessionId: null,
  paymentsLists: [],
  paymentLoading: false,
}

export const initiateSubscripton = createAsyncThunk('initiateSubscripton', async data => {
  const res = await postRequest(`subscription/${data.subscriptionId}`, data)
  return res
})

export const getSubscriptonDetails = createAsyncThunk('getSubscriptonDetails', async data => {
  const res = await getRequest(`subscription/${data.subscriptionId}`)
  return res
})
export const getSubscriptonPayments = createAsyncThunk('getSubscriptonPayments', async () => {
  const res = await getRequest(`payment/membership`)
  return res
})

const agencySubscriptionReducer = createSlice({
  name: 'agencySubscription',
  initialState,
  reducers: {
    resetValues: state => {
      state.error = ''
      state.isSuccess = false
      state.sessionId = null
    },
  },
  extraReducers: {
    [initiateSubscripton.fulfilled]: (state, { payload: { result, error } }) => {
      state.loading = false
      if (error) {
        state.error = error
      } else {
        state.isSuccess = true
        state.error = null
        state.sessionId = result.sessionId
      }
    },
    [initiateSubscripton.pending]: state => {
      state.loading = true
    },
    [getSubscriptonDetails.fulfilled]: (state, { payload: { result, error } }) => {
      state.loading = false
      if (error) {
        state.error = error
      } else {
        state.isSuccess = true
        state.error = null
        state.details = result
      }
    },
    [getSubscriptonDetails.pending]: state => {
      state.loading = true
    },
    [getSubscriptonPayments.fulfilled]: (state, { payload: { data, error } }) => {
      state.paymentLoading = false
      if (error) {
        state.error = error
      } else {
        state.isSuccess = true
        state.error = null
        state.paymentsLists = data
      }
    },
    [getSubscriptonPayments.pending]: state => {
      state.paymentLoading = true
    },
  },
})
export const { resetValues } = agencySubscriptionReducer.actions
export default agencySubscriptionReducer.reducer
