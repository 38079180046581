import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { postRequest, patchRequest, getRequest } from 'api/httpRequests'

const userData =
  localStorage.getItem('user') === 'undefined' ? null : JSON.parse(localStorage.getItem('user'))
const tokensData =
  localStorage.getItem('tokens') === 'undefined' ? null : JSON.parse(localStorage.getItem('tokens'))

const initialState = {
  tokens: tokensData || null,
  loading: false,
  error: '',
  user: userData || null,
  invitedTeam: null,
  isSuccess: false,
  otpData: null,
  successMsg: '',
}

export const signUpUser = createAsyncThunk('signupUser', async data => {
  const res = await postRequest('auth/register', data)
  return res
})

// export const signInUser = createAsyncThunk('signinUser', async data => {
//   const res = await postRequest('auth/login', data)
//   return res
// })

export const sendOtp = createAsyncThunk('sendOtp', async data => {
  const res = await postRequest('auth/send-otp', data)
  return res
})

export const verifyOtp = createAsyncThunk('verifyOtp', async data => {
  const res = await postRequest('auth/verify-otp', data)
  return res
})

export const inviteTeam = createAsyncThunk('inviteTeam', async data => {
  const res = await postRequest('invite-team', data)
  return res
})
export const subscription = createAsyncThunk('subscription', async data => {
  const res = await postRequest('subscription', data)
  return res
})

export const updateUser = createAsyncThunk('updateUser', async data => {
  const { userId } = data
  delete data.userId
  const res = await patchRequest(`user/${userId}`, data)
  return res
})
export const getUser = createAsyncThunk('getUser', async data => {
  const { userId } = data
  delete data.userId
  const res = await getRequest(`user/${userId}`, data)
  return res
})

export const updateUserPassword = createAsyncThunk('updateUserPassword', async data => {
  const { userId } = data
  delete data.userId
  const res = await patchRequest(`user/password-update/${userId}`, data)
  return res
})
const authReducer = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetValues: state => {
      state.error = ''
      state.isSuccess = false
    },
  },
  extraReducers: {
    [signUpUser.fulfilled]: (state, { payload: { error, user, tokens } }) => {
      state.loading = false
      if (error) {
        state.error = error
      } else {
        localStorage.setItem('user', JSON.stringify(user))
        localStorage.setItem('tokens', JSON.stringify(tokens))
        state.isSuccess = true
        state.error = null
        state.user = user
        state.tokens = tokens
      }
    },
    [signUpUser.pending]: state => {
      state.loading = true
    },

    [inviteTeam.pending]: state => {
      state.loading = true
    },
    [inviteTeam.fulfilled]: (state, { payload: { error, data } }) => {
      state.loading = false
      if (error) {
        state.error = error
      } else {
        state.isSuccess = true
        state.error = null
        state.invitedTeam = state.invitedTeam ? [...state.invitedTeam, ...data] : data
      }
    },

    [subscription.fulfilled]: (state, { payload: { error } }) => {
      if (error) {
        state.error = error
      } else {
        state.error = null
      }
    },
    [updateUser.pending]: state => {
      state.loading = true
    },
    [updateUser.fulfilled]: (state, { payload: { error, user } }) => {
      state.loading = false
      if (error) {
        state.error = error
      } else {
        localStorage.setItem('user', JSON.stringify(user))
        state.isSuccess = true
        state.error = null
        state.user = user
      }
    },
    [updateUserPassword.pending]: state => {
      state.loading = true
    },
    [updateUserPassword.fulfilled]: (state, { payload: { error } }) => {
      state.loading = false
      if (error) {
        state.error = error
      } else {
        state.isSuccess = true
        state.error = null
      }
    },
    [getUser.pending]: state => {
      state.loading = true
    },
    [getUser.fulfilled]: (state, { payload: { error, user } }) => {
      state.loading = false
      if (error) {
        state.error = error
      } else {
        localStorage.setItem('user', JSON.stringify(user))
        state.isSuccess = true
        state.error = null
        state.user = user
      }
    },

    [sendOtp.pending]: state => {
      state.loading = true
      state.successMsg = ''
    },
    [sendOtp.fulfilled]: (
      state,
      {
        payload: { error, result },
        meta: {
          arg: { phone },
        },
      },
    ) => {
      state.loading = false
      if (error) {
        state.error = error
        state.successMsg = ''
      } else {
        state.isSuccess = true
        state.error = null
        state.otpData = { ...result, phone }
        state.successMsg = 'OTP sent successfully'
      }
    },
    [verifyOtp.pending]: state => {
      state.loading = true
      state.successMsg = ''
    },
    [verifyOtp.fulfilled]: (state, { payload: { error, user, tokens, result } }) => {
      state.loading = false
      if (error || result?.Status === 'Error') {
        state.error = error || result.Details
        state.successMsg = ''
      } else {
        localStorage.setItem('user', JSON.stringify(user))
        localStorage.setItem('tokens', JSON.stringify(tokens))
        state.isSuccess = true
        state.error = null
        state.user = user
        state.tokens = tokens
        state.otpData = null
        state.successMsg = 'You have successfully verified your account'
      }
    },
  },
})
export const { resetValues } = authReducer.actions
export default authReducer.reducer
