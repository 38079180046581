import { configureStore } from '@reduxjs/toolkit'
import authReducer from './reducers/authReducer'
import taskReducer from './reducers/taskReducer'
import contactReducer from './reducers/contactReducer'
import paymentReducer from './reducers/paymentReducer'
import agencyPlanReducer from './reducers/agencyPlanReducer'
import agencySubscriptionReducer from './reducers/agencySubscriptionReducer'
import activityReducer from './reducers/activityReducer'
import userReducer from './reducers/userReducer'

const store = configureStore({
  reducer: {
    auth: authReducer,
    tasks: taskReducer,
    contacts: contactReducer,
    payment: paymentReducer,
    agencyPlan: agencyPlanReducer,
    subscriptions: agencySubscriptionReducer,
    activities: activityReducer,
    users: userReducer
  },
})

export default store
