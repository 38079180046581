import React, { useState, useEffect } from 'react'
import AppRouter from 'routes/router'
import Loading from 'components/Loading'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { getSubscriptonDetails } from 'store/reducers/agencySubscriptionReducer'

const App = () => {
  const dispatch = useDispatch()
  const auth = localStorage.getItem('user') || false
  const userData = auth && auth !== 'undefined' ? JSON.parse(auth) : null
  const { details, loading } = useSelector(state => state.subscriptions)
  const [isSubscriptionExpired, setIsSubscriptionExpired] = useState(false)

  useEffect(() => {
    if (userData?.subscriptionId) {
      dispatch(getSubscriptonDetails({ subscriptionId: userData?.subscriptionId?.id }))
    }
  }, [])

  useEffect(() => {
    let diff = null
    if (details && details?.nextRenewalDate) {
      const nextRenewalDate = moment(details?.nextRenewalDate)
      diff = nextRenewalDate.diff(moment(), 'days')
    } else {
      diff = moment(new Date(details?.trialEndDate)).diff(new Date(), 'days')
    }
    setIsSubscriptionExpired(diff <= 0)
  }, [details])

  return (
    <>
      {loading && !details ? (
        <Loading />
      ) : (
        <>
          <AppRouter isSubscriptionExpired={isSubscriptionExpired} />
        </>
      )}
    </>
  )
}

export default App
