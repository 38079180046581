import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  postRequest,
  getRequest,
  patchRequest,
  deleteRequest,
  getRequestWithCancelation,
} from 'api/httpRequests'
import axios from 'axios'

const { CancelToken } = axios
let cancel

const initialState = {
  loading: false,
  submitLoading: false,
  error: '',
  dataLists: null,
  dataList: null,
  isSuccess: false,
  optionsData: null,
  searchLoading: false,
  searchFilterData: null,
  selectedContacts: [],
  profileShareWith: [],
  profileShareWithStatic: [],
  singleAgency: null,
  getGenderList: [],
}

export const postContact = createAsyncThunk('createContact', async data => {
  const { agencyId, isPublic } = data
  delete data.agencyId
  delete data.isPublic
  delete data.onBoarded
  const endPointUrl = isPublic ? `contact/${agencyId}?isPublic=true` : `contact/${agencyId}`
  const res = await postRequest(endPointUrl, data)
  return res
})

export const getContacts = createAsyncThunk('getContacts', async queryData => {
  // console.log({ signal, source, queryData })
  // signal.addEventListener('abort', () => {
  //   source.cancel('aborted')
  // })
  const query = queryData || ''
  // const getToken = localStorage.getItem('tokens')
  // const accessToken = JSON.parse(getToken)
  // const { token } = accessToken?.access || null
  // console.log({ cancel })
  if (cancel) {
    cancel('canecled')
  }
  const res = await getRequestWithCancelation(`/contact?${query}`, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c
    }),
  })
  console.log({ res })
  return res
})

export const getSharedContacts = createAsyncThunk('getSharedContacts', async queryData => {
  const { agencyId, filterDataValues } = queryData
  console.log('query==>', `/contact/shared-contacts/${agencyId}?${filterDataValues}`)
  const res = await getRequest(`/contact/shared-contacts/${agencyId}?${filterDataValues}`)
  return res
})

export const getSingleContact = createAsyncThunk('getSingleContact', async queryData => {
  const { agencyId, contactId, isPublic, bioData } = queryData
  let endPointUrl = isPublic
    ? `contact/${agencyId}/${contactId}?isPublic=true`
    : `contact/${agencyId}/${contactId}`
  endPointUrl = bioData ? `${endPointUrl}&bioData=true` : endPointUrl
  const res = await getRequest(endPointUrl)
  console.log('🚀 ~ getSingleContact ~ ````````````res:', res)

  return res
})

export const getOnBoardedContacts = createAsyncThunk('getOnBoardedContacts', async queryData => {
  const query = queryData || ''
  console.log('QUERY==>', `contact/onboarded-contacts?${query}`)
  const res = await getRequest(`contact/onboarded-contacts?${query}`)
  console.log('Get OnBoard Data ===>', res)
  return res
})

export const updateContact = createAsyncThunk('updateContact', async data => {
  const { contactId, agencyId, isPublic } = data
  delete data.contactId
  delete data.agencyId
  delete data.id
  delete data.isPublic
  delete data.onBoarded
  if (data.plan.id) {
    data.plan = data.plan.id
  }
  if (data.paymentId) delete data.paymentId
  const endPointUrl = isPublic
    ? `contact/${agencyId}/${contactId}?isPublic=true`
    : `contact/${agencyId}/${contactId}`
  const res = await patchRequest(endPointUrl, data)
  return res
})

export const deleteContact = createAsyncThunk('deleteContact', async data => {
  const { contactId, agencyId } = data
  delete data.contactId
  delete data.agencyId
  delete data.onBoarded
  const res = await deleteRequest(`contact/${agencyId}/${contactId}`)
  return res
})

export const getOptions = createAsyncThunk('getOptions', async () => {
  const res = await getRequest(`contact/data`)
  console.log('🚀 ~ getOptions ~ --------res:', res)
  return res
})

//get Gender count
export const getGenderCount = createAsyncThunk('getGenderCount', async agencyId => {
  const res = await getRequest(`contact/getGenderCounts/${agencyId}`)
  console.log(res, 'response from contact reducer')
  return res
})

export const postSearchFilter = createAsyncThunk('postSearchFilter', async data => {
  const { agencyId } = data
  delete data.onBoarded
  delete data.agencyId
  const res = await postRequest(`contact/${agencyId}/save-search`, data)
  return res
})

export const deleteSearchFilter = createAsyncThunk('deleteSearchFilter', async data => {
  const { agencyId, contactSearchId } = data
  delete data.agencyId
  delete data.onBoarded
  delete data.contactSearchId
  const res = await deleteRequest(`contact/${agencyId}/save-search/${contactSearchId}`)
  return res
})

export const getSearchFilter = createAsyncThunk('getSearchFilter', async data => {
  const { agencyId } = data
  const res = await getRequest(`contact/${agencyId}/save-search`)
  return res
})

const contactReducer = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    resetValues: state => {
      state.error = ''
      state.isSuccess = false
    },
    resetDataList: state => {
      state.dataList = null
    },
    resetDataLists: state => {
      state.dataLists = null
    },
    setSelectedContacts: (state, action) => {
      state.selectedContacts = action.payload
    },
    profileShareWithData: (state, action) => {
      state.profileShareWith = action.payload.sharedWith
    },
    profileShareWithDataStatic: (state, action) => {
      state.profileShareWithStatic = action.payload.sharedWith
    },
    setSingleAgency: (state, payload) => {
      state.singleAgency = payload.payload
    },
  },
  extraReducers: {
    [postContact.fulfilled]: (state, { payload: { error, result } }) => {
      state.submitLoading = false
      if (error) {
        state.error = error
      } else {
        state.isSuccess = true
        state.error = null
        state.dataList = { ...result }
      }
    },
    [postContact.pending]: state => {
      state.submitLoading = true
    },
    [postSearchFilter.fulfilled]: (state, { payload: { error } }) => {
      state.searchLoading = false
      if (error) {
        state.error = error
      } else {
        state.isSuccess = true
        state.error = null
      }
    },
    [postSearchFilter.pending]: state => {
      state.searchLoading = true
    },
    [getSearchFilter.fulfilled]: (state, { payload: { error, results } }) => {
      state.searchLoading = false
      if (error) {
        state.error = error
      } else {
        state.error = null
        state.searchFilterData = results
      }
    },
    [getSearchFilter.pending]: state => {
      state.searchLoading = true
    },
    [getContacts.pending]: state => {
      state.dataLists = null
      state.loading = true
    },
    [getSingleContact.fulfilled]: (state, { payload: { error, result } }) => {
      state.loading = false
      if (error) {
        state.error = error
      } else {
        state.error = null
        state.dataList = { ...result }
      }
    },
    [getSingleContact.pending]: state => {
      state.loading = true
    },
    [getContacts.fulfilled]: (state, { payload }) => {
      if (payload.error) {
        state.error = payload.error
      } else {
        state.error = null
        state.dataLists = payload
      }
      state.loading = false
    },
    [getGenderCount.pending]: state => {
      state.loading = true
    },
    [getGenderCount.fulfilled]: (state, { payload }) => {
      console.log(payload, 'payload from contact list')
      if (payload.error) {
        state.error = payload.error
      } else {
        state.error = null
        state.getGenderList = payload
      }
      state.loading = false
    },

    [getOnBoardedContacts.fulfilled]: (state, { payload }) => {
      if (payload.error) {
        state.error = payload.error
      } else {
        state.error = null
        state.dataLists = payload.results
      }
      state.loading = false
    },
    [getOnBoardedContacts.pending]: state => {
      state.dataLists = null
      state.loading = true
    },
    [getOptions.fulfilled]: (state, { payload: { error, data } }) => {
      if (error) {
        state.error = error
      } else {
        state.error = null
        // eslint-disable-next-line prefer-destructuring
        state.optionsData = data[0]
      }
      state.loading = false
    },
    [getSharedContacts.pending]: state => {
      state.dataLists = null
      state.loading = true
    },
    [getSharedContacts.fulfilled]: (state, { payload }) => {
      if (payload.error) {
        state.error = true
      } else {
        state.error = null
        // eslint-disable-next-line prefer-destructuring
        state.dataLists = payload
      }
      state.loading = false
    },
    [getOptions.pending]: state => {
      state.loading = true
    },
    [updateContact.fulfilled]: (state, { payload: { error, result } }) => {
      if (error) {
        state.error = error
        state.isSuccess = false
      } else {
        state.error = null
        state.isSuccess = true
        state.dataList = result
      }
      state.submitLoading = false
    },
    [updateContact.pending]: state => {
      state.submitLoading = true
    },
    [deleteContact.fulfilled]: (state, { payload: { error, contactId } }) => {
      if (error) {
        state.error = error
      } else {
        state.error = null
        state.dataLists = state.dataLists.results.filter(x => x.id !== contactId)
      }
    },
  },
})
export const {
  resetValues,
  resetDataList,
  resetDataLists,
  setSelectedContacts,
  profileShareWithData,
  profileShareWithDataStatic,
  setSingleAgency,
} = contactReducer.actions
export default contactReducer.reducer
