import axiosClient from './axiosClient'

const getRequest = endPoint => {
  return axiosClient
    .get(endPoint)
    .then(response => response.data)
    .catch(error => ValidateAxiosError(error))
}

const getRequestWithCancelation = (endPoint, extraConfig) => {
  return axiosClient
    .get(endPoint, { ...extraConfig })
    .then(response => response.data)
    .catch(error => ValidateAxiosError(error))
}

const postRequest = (endPoint, payload, config = {}) => {
  return axiosClient
    .post(endPoint, payload, config)
    .then(response => response.data)
    .catch(error => ValidateAxiosError(error))
}

const patchRequest = (endPoint, payload) => {
  return axiosClient
    .patch(endPoint, payload)
    .then(response => response.data)
    .catch(error => ValidateAxiosError(error))
}
const putRequest = (endPoint, payload) => {
  return axiosClient
    .put(endPoint, payload)
    .then(response => response.data)
    .catch(error => ValidateAxiosError(error))
}

const deleteRequest = endPoint => {
  return axiosClient
    .delete(endPoint)
    .then(response => response.data)
    .catch(error => ValidateAxiosError(error))
}

const ValidateAxiosError = error => {
  const message =
    (error.response && error.response.data && error.response.data.message) ||
    error.message ||
    error.toString()
  console.log({ message })
  // TODO: Need to implement refresh token
  if (message === 'Please authenticate') {
    localStorage.clear()
    window.location.reload()
  }
  return { error: message }
}

export {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
  putRequest,
  getRequestWithCancelation,
}
