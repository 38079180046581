import React from 'react'
import logo from 'assets/images/auth/logo.svg'
import RedirectionImage from 'assets/images/redirection-mobile.svg'
import './mobileRedirection.css'

const Index = () => {
  return (
    <>
      <header className="site-header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-4 col-12">
              <div className="logo-container">
                <a href="https://www.matrimonials.ai/">
                  <div className="d-flex logo">
                    <img src={logo} alt="iWaada" />
                    <div className="logo-text">
                      <span className="italic-text">i</span>Waada
                      <div className="tagline">
                        Happy <span>Matchmakers</span> <span className="orange-color">Happier</span>{' '}
                        Marriages
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-sm-8 col-12" />
          </div>
        </div>
      </header>
      <section className="redirection-wrapper">
        <div className="container">
          <div className="text-center page-heading">
            <h1 className="section-title"> Continue on Desktop</h1>
            <div className="divider" />
            <h4>
              {' '}
              For a seamless experience and higher interactivity we recommend you to please move to
              desktop version{' '}
            </h4>
          </div>
          <div className="redirection-content text-center">
            <div className="image-container">
              <img src={RedirectionImage} alt="" />
            </div>
            <div className="action-button">
              <a href="https://www.matrimonials.ai/" className="btn btn-primary">
                {' '}
                Go to main page{' '}
              </a>{' '}
              <br />
              <a href="" className="btn btn-primary btn-link">
                {' '}
                Download the app <span>(Coming Soon)</span>{' '}
              </a>
            </div>
          </div>
        </div>
      </section>
      <div className="dark-overlay" />
    </>
  )
}

export default Index
