import axios from 'axios'

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: 'http://192.168.43.182:5000/v1/api',
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
  },
})

axiosClient.interceptors.request.use(
  request => {
    const getToken = localStorage.getItem('tokens')
    if (getToken && getToken !== 'undefined') {
      const accessToken = JSON.parse(getToken)
      const { token } = accessToken?.access || null
      request.headers.common.Authorization = `Bearer ${token}`
    }
    return request
  },
  error => error,
)

axiosClient.interceptors.response.use(undefined, error => {
  console.log(error)
  return Promise.reject(error)
})

export default axiosClient
