import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { postRequest, getRequest, patchRequest, deleteRequest } from 'api/httpRequests'

const initialState = {
  loading: false,
  error: '',
  data: null,
  isSuccess: false,
  completedCount: 0,
}

export const postTask = createAsyncThunk('createTask', async data => {
  const res = await postRequest('task', data)
  return res
})
export const getTasks = createAsyncThunk('getTasks', async queryData => {
  const query = queryData || ''
  const res = await getRequest(`task?${query}`)
  return res
})

export const getCompletedTasks = createAsyncThunk('getCompletedTasks', async queryData => {
  const query = queryData || ''
  const res = await getRequest(`task/completed?${query}`)
  return res
})

export const updateTask = createAsyncThunk('updateTask', async data => {
  const { taskId } = data
  delete data.taskId
  const res = await patchRequest(`task/${taskId}`, data)
  return res
})

export const deleteTask = createAsyncThunk('deleteTask', async data => {
  const { taskId } = data
  delete data.taskId
  const res = await deleteRequest(`task/${taskId}`)
  return res
})

const taskReducer = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    resetValues: state => {
      state.error = ''
      state.isSuccess = false
    },
  },
  extraReducers: {
    [postTask.fulfilled]: (state, { payload: { error, task } }) => {
      state.loading = false
      if (error) {
        state.error = error
      } else {
        state.isSuccess = true
        state.error = null
        state.data = [{ ...task }, ...state.data]
      }
    },
    [postTask.pending]: state => {
      state.loading = true
    },
    [getTasks.fulfilled]: (state, { payload: { error, results } }) => {
      state.loading = false
      if (error) {
        state.error = error
      } else {
        state.error = null
        state.data = results
      }
    },
    [getTasks.pending]: state => {
      state.loading = true
    },
    [updateTask.fulfilled]: (state, { payload: { error, task } }) => {
      if (error) {
        state.error = error
      } else {
        state.error = null
        state.data = state.data.map(obj => (task.id === obj.id ? task : obj))
      }
    },
    [deleteTask.fulfilled]: (state, { payload: { error, taskId } }) => {
      if (error) {
        state.error = error
      } else {
        state.error = null
        state.data = state.data.filter(x => x.id !== taskId)
      }
    },
    [getCompletedTasks.fulfilled]: (state, { payload: { error, count } }) => {
      if (error) {
        state.error = error
      } else {
        state.error = null
        state.completedCount = count
      }
    },
  },
})
export const { resetValues } = taskReducer.actions
export default taskReducer.reducer
