import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getRequest, postRequest, putRequest, deleteRequest } from 'api/httpRequests'

const initialState = {
  loading: false,
  isSuccess: false,
  error: '',
  agencyUsers: [],
  selectedAgencyUsers: [],
  isShareLoading: false,
  agencyData: [],
  days: null,
  deleteAgency: [],
  addData: null,
  subscriptionId: [],
  agencyDeleted: false,
}

export const getAgencyUsers = createAsyncThunk('getAgencyUsers', async data => {
  const { role, page, sortBy } = data
  const res = await getRequest(`user?&role=${role}&page=${page}&sortBy=${sortBy}`)
  return res
})

export const agencyDetailsList = createAsyncThunk('agencyDetailsList', async data => {
  const res = await postRequest(`user/agencyDetails/list/getAllUser`, data)
  return res
})

export const shareContacts = createAsyncThunk('shareContacts', async data => {
  const res = await postRequest(`contact/share`, data)
  return res
})

export const addAgencyData = createAsyncThunk('addAgencyData', async data => {
  console.log(data,"add agency data")
  const res = await postRequest(`user/agencyDetails/list`, data)
  console.log('res', res)
  return res
})

export const increaseTrailDays = createAsyncThunk('increaseTrailDays', async data => {
  const { subscriptionId, days } = data
  const trialDays = {
    days,
  }
  const res = await putRequest(`subscription/${subscriptionId[0].id}`, trialDays)
  console.log('res', res)
  return res
})

export const deleteAgency = createAsyncThunk('deleteAgency', async data => {
  const { aId } = data
  const endPointUrl = `user/agencyDetails/list?userIds=${aId}`
  const res = await deleteRequest(endPointUrl, data)
  return res
})

const userReducer = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetValues: state => {
      state.error = ''
      state.isSuccess = false
    },
    resetDataList: state => {
      state.agencyData = null
    },
    setSelectedAgenyUsers: (state, { payload }) => {
      state.selectedAgencyUsers = payload
    },
    setAgencyUsers: (state, { payload }) => {
      state.agencyUsers = payload
    },
    setAgencyData: (state, { payload }) => {
      console.log('agency', payload)
      state.agencyData = payload
    },
    setSubscriptionId: (state, { payload }) => {
      console.log('payload', payload)
      state.agencyDeleted = false
      state.subscriptionId = payload
    },
  },
  extraReducers: {
    [getAgencyUsers.pending]: state => {
      state.loading = true
    },
    [getAgencyUsers.fulfilled]: (state, { payload: { results, error } }) => {
      state.loading = false
      if (error) {
        state.error = error
      } else {
        state.error = null
        state.agencyUsers = results
      }
    },
    [agencyDetailsList.pending]: state => {
      state.loading = true
    },
    [agencyDetailsList.fulfilled]: (state, { payload }) => {
      state.agencyData = payload
      state.loading = false
    },
    [shareContacts.pending]: state => {
      state.isShareLoading = true
    },
    [shareContacts.fulfilled]: (state, { payload }) => {
      state.isShareLoading = false
      if (payload.error) {
        state.error = payload.error
        state.isSuccess = false
      } else {
        state.error = null
        state.isSuccess = true
      }
    },
    [addAgencyData.pending]: state => {
      state.isShareLoading = true
    },
    [addAgencyData.fulfilled]: (state, { payload }) => {
      state.isShareLoading = false
      state.addData = payload
    },
    [increaseTrailDays.pending]: state => {
      state.loading = true
    },
    [increaseTrailDays.fulfilled]: (state, { payload }) => {
      state.days = payload
      state.loading = false
    },
    [deleteAgency.fulfilled]: (state, { payload }) => {
      state.agencyDeleted = true
      state.days = payload
      state.loading = false
    },
    [deleteAgency.pending]: state => {
      state.loading = true
    },
  },
})
export const {
  resetValues,
  setSelectedAgenyUsers,
  setAgencyUsers,
  setAgencyData,
  resetDataList,
  setSubscriptionId,
} = userReducer.actions
export default userReducer.reducer
