import React from 'react'
import ReactDOM from 'react-dom'
import { isMobile } from 'react-device-detect'
import { Provider } from 'react-redux'
import store from 'store/store'
import MobileRedirection from 'components/MobileRedirection'
import 'bootstrap/dist/css/bootstrap.css'
import 'assets/styles/styles.css'
import 'react-perfect-scrollbar/dist/css/styles.css'
import App from './app'
import reportWebVitals from './reportWebVitals'

const redirectToMobile = () => <MobileRedirection />
const currentPath = window.location.pathname
ReactDOM.render(
  <React.StrictMode>
    {!currentPath.includes('upload-profile') &&
    !currentPath.includes('upload-success') &&
    !currentPath.includes('payment-status') &&
    isMobile ? (
      redirectToMobile()
    ) : (
      <Provider store={store}>
        <App />
      </Provider>
    )}
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
